import React, { useEffect } from 'react';
import { Form, Field, Checkbox, CheckboxGroup } from '@availity/form';
import * as yup from 'yup';
import { Alert, Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useMutation } from '@tanstack/react-query';
import BlockUi from 'react-block-ui';
import { useFormikContext } from 'formik';
import ChangePassword, { ChangePasswordForm } from '@availity/change-password';

import { login, getPasswordExpired, ExternalChangePasswordApi, passwordConditions, updatePasswordSchema } from '@/api';
import { useAppContext } from '@/context';
import { IS_PROD } from '@/constants';
import { logout } from '../../api/external-send-receive-edi';

type FormValues = {
  username: string;
  password: string;
  showPassword: string[];
};

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

const initialValues: FormValues = { username: '', password: '', showPassword: [] };

const FormFields = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <>
      <Field name="username" label="Username" required />
      <Field name="password" label="Password" required type={values.showPassword.length > 0 ? 'text' : 'password'} />
      <CheckboxGroup name="showPassword" inline>
        <Checkbox label="Show password" value="true" />
      </CheckboxGroup>
    </>
  );
};

const handleChangePasswordSubmit = ({ result, setError, setSuccess }) => {
  if (Array.isArray(result.errors) && result.errors.length > 0) {
    setError(`An error occurred changing your password: ${result.errors[0].message}`);
    setSuccess(null);
  } else if (!result.updateSuccessful) {
    // This is the only situation that can't be caught by the client. Every other password rule should be handled client side before it ever gets to the server
    if (result.warnings.authenticated === false) {
      setError("An error occurred changing your password: The user's current password is incorrect");
    } else {
      setError('An error occurred changing your password');
    }
    setSuccess(null);
  } else {
    setError(null);
    setSuccess('Your password was successfully changed. Close this alert to be redirected to the login screen.');
  }
};

export const Login = () => {
  const {
    isLoggedIn,
    setIsLoggingOut,
    setIsLoggedIn,
    setPasswordExpired,
    isPasswordExpired,
    loggedOutModalIsOpen,
    setLoggedOutModalIsOpen,
    username,
    setUsername,
  } = useAppContext();

  const {
    mutate: handleGetPasswordExpired,
    isLoading: isExpiredLoading,
    isError: isExpiredError,
  } = useMutation(getPasswordExpired, {
    onSettled: (response = false) => {
      setPasswordExpired(response);
    },
  });

  const {
    mutate: handleLogin,
    data: isAuthenticated,
    isLoading: isLoginLoading,
    isError: isLoginError,
    isSuccess: isLoginSuccess,
  } = useMutation(login, {
    onSettled: (response = false) => {
      setIsLoggedIn(response);
      handleGetPasswordExpired();
    },
  });

  const handleOnSubmit = (values: FormValues) => {
    handleLogin(values);
    setUsername(values.username);
  };

  const showLoginError = isLoginError || (isLoginSuccess && !isAuthenticated);

  const toggle = () => setLoggedOutModalIsOpen(!loggedOutModalIsOpen);

  const changePasswordApi = new ExternalChangePasswordApi();

  const { mutate: successfulPasswordChangeToggle, isLoading: isLogoutLoading } = useMutation(logout, {
    onSettled: () => {
      setPasswordExpired(false);
      setIsLoggedIn(false);
      setIsLoggingOut(false);
    },
  });

  useEffect(() => {
    setIsLoggingOut(isLogoutLoading);
  }, [isLogoutLoading, setIsLoggingOut]);

  if (isPasswordExpired && isLoggedIn) {
    return (
      <ChangePassword
        resource={changePasswordApi}
        schema={updatePasswordSchema(username)}
        conditions={passwordConditions(username)}
      >
        <ChangePasswordForm
          onHandleSubmit={handleChangePasswordSubmit}
          onSuccessToggle={successfulPasswordChangeToggle}
          header={<h4>Update Password</h4>}
          maxLength={null}
          additionalButtons={
            <Button
              color="danger"
              onClick={() => {
                setPasswordExpired(false);
              }}
            >
              Cancel
            </Button>
          }
        />
      </ChangePassword>
    );
  }

  return (
    <Card className="login-card">
      <div className="login-card-header">
        <img src="/static/logo.svg" alt="Availity Logo" />
      </div>
      <Modal isOpen={loggedOutModalIsOpen} toggle={toggle}>
        <ModalHeader>Session Expired</ModalHeader>
        <ModalBody>
          We logged you out for security purposes. Reasons for a logout include 30 minutes of inactivity or a login with
          the same user ID on another device.
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="primary">
            Log in to Send Receive EDI
          </Button>
        </ModalFooter>
      </Modal>
      <BlockUi blocking={isLoginLoading || isExpiredLoading} tag={CardBody} body>
        <Alert isOpen={showLoginError} color="danger">
          The username or password does not match. Please try again.
        </Alert>
        <Alert isOpen={!showLoginError && isExpiredError} color="danger">
          An error occurred retrieving the expiration status of your password
        </Alert>
        <div className="av-large-logo" />
        {
          IS_PROD ? (
            <Alert color="warning">We are almost ready for you to use this login page. Until then, continue to log in at <a href="https://ftp.availity.com">https://ftp.availity.com</a></Alert>
          ) : null
        }
        <h1 className="h5">Please enter your credentials</h1>
        <Form initialValues={initialValues} validationSchema={schema} onSubmit={handleOnSubmit}>
          <FormFields />
          <Button block color="primary" type="submit">
            Login
          </Button>
        </Form>
      </BlockUi>
    </Card>
  );
};
