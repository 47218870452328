import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Alert, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Form } from '@availity/form';
import { AvOrganizationSelect } from '@availity/select';

import type { Organization } from '@/types';
import { fetchAllNodes, SendReceiveEdiSpacesApi } from '@/api';
import { IS_PROD, MFT_PERMISSION, OLD_MFT_PERMISSION } from '@/constants';
import { useAppContext } from '@/context';


import * as yup from 'yup';

type FormValues = {
  org: Organization | undefined;
};

const initialValues: FormValues = { org: undefined };

const spacesApi = new SendReceiveEdiSpacesApi({});

export const OrgSelect = () => {
  const { setOrganization } = useAppContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchHomeDirectory = async (customerId: string) => {
    const path = '/';
    queryClient.prefetchQuery(['nodes', path, customerId], () => fetchAllNodes(path, customerId));
  };

  const handleSubmit = async (values) => {
    try {
      if (values.org) {
        setLoading(true);
        setOrganization(values.org as Organization);
        if (IS_PROD) {
          const shouldForceSecureTransport = await spacesApi.getShouldForceSecureTransport();
          if (shouldForceSecureTransport) {
            const url = `/availity/WebUploadServlet?menuTempl=1606&cacheBust=${Date.now()}&Organization=${values.org.customerId}`;
            window.top.location.hash = `#!/loadApp?appUrl=${encodeURIComponent(url)}`;
          } else {
            fetchHomeDirectory(values.org.customerId);
            history.push(`/file-manager/${values.org.customerId}`);
          }
        } else {
          fetchHomeDirectory(values.org.customerId);
          history.push(`/file-manager/${values.org.customerId}`);
        }
      }
    } catch {
      setError('An error occured determining where to redirect you to. Please try again.')
      setLoading(false);
    }
  };

  return (
    <BlockUi blocking={loading}>
      <Alert toggle={() => setError(null)} isOpen={!!error} color="danger" >{error}</Alert>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={yup.object().shape({
          org: yup.object().required('You must select an organization to continue.'),
        })}
      >
        <AvOrganizationSelect
          className="mb-0"
          required
          id="org"
          name="org"
          label="Select an Organization to manage files"
          parameters={{
            permissionId: [MFT_PERMISSION, OLD_MFT_PERMISSION],
          }}
          permissionIds={[MFT_PERMISSION, OLD_MFT_PERMISSION]}
        />
        <Button block type="submit" color="primary">
          Submit
        </Button>
      </Form>
    </BlockUi>
  );
};
