import React, { useState } from 'react';
import { Alert, BlockUi, Button, Card, CardContent, CardHeader, BoxIcon, FolderIcon, OpenInNewIcon, UserIcon, Table, TableRow, TableCell, Stack, Box, TableHead, TableBody, TableFooter, TablePagination } from '@availity/element';
import { Collapse } from '@mui/material';
import Authorize from '@availity/authorize';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '@/context';

import { MailboxInfo } from './MailboxInfo';
import { GetTempAccessUrl } from '../../GetTempAccessUrl';
import { MFT_TEMPORARY_ACCESS_URL_PERMISSON, MFT_SUPER_USER_PERMISSON } from '../../../constants';

const CollapsibleTableRow = ({
  folder,
  onFolderSelect,
  mbxType,
  setValuesCopy,
  setSearchType,
  submitFormCopy,
}: {
  folder: string;
  onFolderSelect: () => void;
  mbxType: string;
  setValuesCopy: (values: any) => void;
  setSearchType: (searchType: string) => void;
  submitFormCopy: () => Promise;
}) => {
  const { setMbx } = useAppContext();

  const [mailboxIsOpen, setMailboxIsOpen] = useState(false);
  const [isCreateUserOpen, setCreateUserOpen] = useState(false);
  const [tempAccessUrlIsOpen, setTempAccessUrlIsOpen] = useState(false);

  const closeAllCollapses = () => {
    setMailboxIsOpen(false);
    setTempAccessUrlIsOpen(false);
  };

  const toggleMailboxIsOpen = () => {
    closeAllCollapses();
    if (!mailboxIsOpen) {
      setMailboxIsOpen(true);
    }
  };
  const toggleCreateUser = () => {
    setCreateUserOpen(!isCreateUserOpen);
  };

  const toggleTempAccessUrlIsOpen = () => {
    closeAllCollapses();
    if (!tempAccessUrlIsOpen) {
      setTempAccessUrlIsOpen(true);
    }
  };

  const history = useHistory();

  const [isEditMode] = useState(false);

  const displayFolder = folder.split('/')[2] || folder.split('/')[1] || '/';

  return (
    <TableRow>
      <TableCell>
        <Box sx={{fontSize: '18px', my: .5}}>
          {displayFolder}
          <Stack className="float-right" spacing={1} direction="row" useFlexGap flexWrap="wrap" sx={{float: 'right'}} role="group" aria-label={`${displayFolder} actions`}>
            <Button onClick={onFolderSelect} endIcon={<FolderIcon/>} color="secondary">
              View Files
            </Button>
            <Button onClick={toggleMailboxIsOpen} endIcon={<BoxIcon/>} color="secondary">
              {mailboxIsOpen ? 'Close' : 'Mailbox Info'}
            </Button>
            <Authorize permissions={[MFT_SUPER_USER_PERMISSON]} unauthorized={null} loader={<BlockUi blocking/>}>
            <Button
              onClick={() => {
                  toggleCreateUser();
                  history.push(`/create-user`);
                  setMbx(
                  { id: `${folder?.slice(0, -1)}`,
                    entry: `${folder?.slice(0, -1).split("/").pop()}`,
                    target: `${folder?.slice(0,-1)}`} )
                }
              }
              endIcon={<UserIcon/>}
              color="secondary"
            >
              {isCreateUserOpen ? 'Close' : 'Create User'}
            </Button>
            </Authorize>
            <Authorize permissions={[MFT_TEMPORARY_ACCESS_URL_PERMISSON]} unauthorized={null} loader={<BlockUi blocking/>}>
              <Button onClick={toggleTempAccessUrlIsOpen} endIcon={<OpenInNewIcon aria-hidden/>} color="secondary">
                {tempAccessUrlIsOpen ? 'Close' : 'Temporary Access URL'}
              </Button>
            </Authorize>
          </Stack>
        </Box>
        <Collapse in={mailboxIsOpen}>
          <MailboxInfo
            target={folder.length > 1 ? folder.slice(0, -1) : folder}
            enabled={mailboxIsOpen}
            isEditMode={isEditMode}
            setValuesCopy={setValuesCopy}
            setSearchType={setSearchType}
            submitFormCopy={submitFormCopy}
          />
        </Collapse>
        <Collapse in={tempAccessUrlIsOpen}>
          { // Get rid of duplicate id warnings
            tempAccessUrlIsOpen ? <GetTempAccessUrl mbxType={mbxType} customerId={displayFolder} /> : null
          }
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const ITEMS_PER_PAGE = 25;

export const FolderTable = ({
  folders,
  onFolderSelect,
  prefix,
  originalMbx,
  mbxType,
  setValuesCopy,
  setSearchType,
  submitFormCopy,
}: {
  folders: string[];
  onFolderSelect: (folder: string) => void;
  prefix: string;
  originalMbx: string;
  mbxType: string;
  setValuesCopy: (values: any) => void;
  setSearchType: (searchType: string) => void;
  submitFormCopy: () => Promise;
}) => {
  const [currentPage, setcurrentPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setcurrentPage(newPage);
  };

  const page = folders.slice(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE);

  return (
    <Card sx={{mb: 2}}>
      <CardHeader title={`Results for: ${prefix}`} titleTypographyProps={{component:"h3"}}/>
      <CardContent>
        {folders.length > 0 ? (
          <Table striped size="medium" sx={{fontSize: '18px'}}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: 'inherit', borderTop: 'none'}}>Mailbox</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {page.map((folder) => (
                  <CollapsibleTableRow mbx={originalMbx} mbxType={mbxType} key={folder} onFolderSelect={() => onFolderSelect(folder)} folder={folder} setValuesCopy={setValuesCopy} setSearchType={setSearchType} submitFormCopy={submitFormCopy} />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={12}
                    count={folders.length}
                    rowsPerPage={ITEMS_PER_PAGE}
                    rowsPerPageOptions={[ITEMS_PER_PAGE]}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    paginationProps={{variant:"outlined", size: 'large'}}
                  />
                </TableRow>
              </TableFooter>
            </Table>
        ) : (
          <Alert severity="warning">
            No mailboxes were returned from your search.
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};
